<template>

  <v-sheet
    class="overflow-y-auto"
  >
    <v-parallax
      fill-height
      dark
      src="/img/bg-orange.jpg"
      height="600"
    >
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          class="text-center"
          cols="12"
        >
          <v-slide-y-transition>
            <div v-show="show">
              <img
                src="/img/curepay-logo-vertical-small.png"
                height="100"
                width="auto"
                alt="CURE Token Logo"
                style="display: block; margin:auto;"
                class="mb-10"
              />           
              <h1 class="text-h2 font-weight-thin mt-12">
                Buying crypto is difficult.
              </h1>
              <h2 class="my-4 font-weight-light">
                That’s why we’ve made it easy.
                Use traditional payment methods and secure your piece of CURE.
              </h2>
            </div>
          </v-slide-y-transition>
        </v-col>
      </v-row>
    </v-parallax>

    <v-main class="my-12">
      <v-container>
        <v-row>
          <v-col
            cols="12"
            sm="6"
          >
            <v-sheet
              rounded="sm"
              min-height="268"
            >
              <v-container>
                <v-row justify="center" >
                  <v-col sm="11">
                    <v-stepper
                      v-model="currentStep"
                      :elevation="20"
                    >
                      <v-stepper-header>
                        <template v-for="(step, index) in steps">
                          <v-stepper-step
                            :key="`${step.title}-step`"
                            :complete="currentStep > index + 1"
                            :step="index + 1"
                            color="orange"
                          >
                            {{ step.title }}
                          </v-stepper-step>
                          <v-divider v-if="index + 1 !== steps.length" :key="step.title" />
                        </template>
                      </v-stepper-header>

                      <v-stepper-items>
                        <v-stepper-content
                          v-for="(step, index) in steps"
                          :key="`${step.title}-content`"
                          :step="index + 1"
                        >
                          <component
                            :is="step.component"
                            @purchase="purchaseCURE($event)"
                            @complete="currentStep = 3"
                          ></component>
                        </v-stepper-content>
                      </v-stepper-items>
                    </v-stepper>
                  </v-col>
                </v-row>
              </v-container>
            </v-sheet>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            order-sm="first"
            order-md="first"
          >
            <v-sheet
              min-height="50vh"
              rounded="lg"
              class="ma-4"
            >
              <v-flex>
                <h2>Introducing CURE Pay.</h2>
                <p class="pr-10">
                  The easy way to buy <a href='https://www.curetoken.net/' target='_blank'>CURE Token</a>, and support the cause. We handle everything in real-time, so that you don’t have to. Once completed, you will have your own secure dashboard to access your tokens.
                  <br><br>
                  The result is that you have an easy to manage place to store your CURE. If at any point you want to sell, we provide you with online educational resources to help with that process.
                </p>
                <v-container class="mb-8" style="max-width: 600px;">
                <v-timeline
                  dense
                  clipped
                >
                  <v-timeline-item
                    color="orange"
                    icon-color="grey lighten-2"
                    large
                    fill-dot
                  >
                    <template v-slot:icon>
                      <span class="white--text font-weight-black headline">1</span>
                    </template>
                    <v-row justify="space-between">
                      <v-col cols="12" class="mt-6">
                        <h3>Choose An Amount</h3>
                        <span class="body-2">Enter the amount of USD
                        that you would like to buy.</span>
                      </v-col>
                    </v-row>
                  </v-timeline-item>

                  <v-timeline-item
                    color="orange"
                    icon-color="grey lighten-2"
                    large
                    fill-dot
                  >
                    <template v-slot:icon>
                      <span class="white--text font-weight-black headline">2</span>
                    </template>
                    <v-row justify="space-between">
                      <v-col cols="12" class="mt-6">
                        <h3>Enter Payment Details</h3>
                        <span class="body-2">You can buy via credit card,
                        debit card, and even Apple Pay.</span>
                      </v-col>
                    </v-row>
                  </v-timeline-item>

                  <v-timeline-item
                    color="orange"
                    icon-color="grey lighten-2"
                    large
                    fill-dot
                  >
                    <template v-slot:icon>
                      <span class="white--text font-weight-black headline">3</span>
                    </template>
                    <v-row justify="space-between">
                      <v-col cols="12" class="mt-6">
                        <h3>Receive Your Tokens</h3>
                        <span class="body-2">You will get a confirmation
                        email with your token.</span>
                      </v-col>
                    </v-row>
                  </v-timeline-item>

                </v-timeline>
              </v-container>
              <h2>About CURE Token</h2>
                <p class="pr-10">
                  CURE is dedicated to using the power of decentralized finance to help rid the planet of dreadful diseases like cancer. Partnering with global research teams and drawing from a wealth of experience in the innovation, technological, and charitable sectors, CURE aims to go one step further than simply becoming the world's most successful charity token. Learn more at <a href='https://www.curetoken.net/' target='_blank'>www.curetoken.net</a>
                </p>
              </v-flex>
            </v-sheet>
          </v-col>

          
        </v-row>
      </v-container>
    </v-main>
  </v-sheet>
</template>
<style>
  a {
    color: orange !important;
  }
</style>
<script>
import axios from 'axios';
// import Alert from './Alert.vue';
import Calculate from './Calculate.vue';
import Process from './Process.vue';
import Complete from './Complete.vue';

// const path = 'http://127.0.0.1:5000/get_current_price';
// const domainPath = 'https://cure-pay-server.herokuapp.com';
// const domainPath = 'http://127.0.0.1:5000';

export default {
  data() {
    return {
      price: [],
      message: '',
      show: false,
      showMessage: false,
      stripe: null,
      currentStep: this.$route.query.session_id ? 2 : 1,
      steps: [
        {
          title: 'Calculate',
          component: 'Calculate',
        },
        {
          title: 'Process',
          component: 'Process',
        },
        {
          title: 'Complete',
          component: 'Complete',
        },
      ],
    };
  },
  components: {
    Calculate,
    Process,
    Complete,
  },
  methods: {
    get_current_price() {
      const path = `${this.$domainPath}/get_current_price`;
      // const path = 'https://cure-pay-server.herokuapp.com/get_current_price';
      axios.get(path)
        .then((res) => {
          this.price = res.data.price;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
    },
    purchaseCURE(purchaseAmount) {
      console.log("purchaseCURE");
      console.log(purchaseAmount);
      // Get Checkout Session ID
      // fetch('https://cure-pay-server.herokuapp.com/create-checkout-session', {

      console.log(purchaseAmount);

      fetch(`${this.$domainPath}/create-checkout-session`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ purchase_amount: purchaseAmount }),
      })
        .then((result) => result.json())
        .then((data) => {
          console.warn(data);
          // Redirect to Stripe Checkout
          return this.stripe.redirectToCheckout({ sessionId: data.sessionId });
        })
        .then((res) => {
          console.log(res);
        });
    },
    getStripePublishableKey() {
      // fetch('https://cure-pay-server.herokuapp.com/config')
      fetch(`${this.$domainPath}/config`)
        .then((result) => result.json())
        .then((data) => {
          // Initialize Stripe.js
          this.stripe = Stripe(data.publicKey); // eslint-disable-line no-undef
        });
    },
  },
  created() {
    window.location.href = 'https://www.curepayapp.com';
    // this.get_current_price();
    //this.getStripePublishableKey();
    //show
    //setTimeout(() => { this.show = true; }, 500);
  },
};
</script>
