var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [[_c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-dialog', {
    attrs: {
      "persistent": "",
      "max-width": "500"
    },
    model: {
      value: _vm.whitelistModal,
      callback: function callback($$v) {
        _vm.whitelistModal = $$v;
      },
      expression: "whitelistModal"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "text-h5"
  }, [_vm._v(" Your request has been sent. ")]), _c('v-card-text', [_vm._v("We will follow up with you shortly to coordinate the whitelisting.")]), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    staticClass: "white--text",
    attrs: {
      "color": "orange"
    },
    on: {
      "click": function click($event) {
        _vm.whitelistModal = false;
      }
    }
  }, [_vm._v(" Close ")])], 1)], 1)], 1)], 1)], _c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('h3', {
    staticClass: "white--text"
  }, [_vm._v("Whitelist transfer window will close in:")]), _c('flip-countdown', {
    attrs: {
      "deadline": "2022-1-15 12:00:00"
    },
    on: {
      "timeElapsed": _vm.timeElapsedHandler
    }
  }), _c('v-card', {
    staticClass: "pa-4 animated fadeIn",
    attrs: {
      "id": "transferHolder"
    }
  }, [_c('v-card-title', [_c('span', {
    staticClass: "text-h5"
  }, [_vm._v("Request LBank Whitelist")])]), _c('v-card-text', [_c('p', {}, [_vm._v("We can whitelist your LBank wallet in order to transfer CURE Tokens to the exchange. This will remove the 10% tax when transfering tokens.")]), _c('v-form', {
    model: {
      value: _vm.isFormValid,
      callback: function callback($$v) {
        _vm.isFormValid = $$v;
      },
      expression: "isFormValid"
    }
  }, [_c('v-text-field', {
    staticClass: "mt-4",
    attrs: {
      "label": "LBank Wallet Address",
      "required": "",
      "color": "orange",
      "id": "walletAddress",
      "rules": [function (v) {
        return !!v || 'This field is required';
      }, function (v) {
        return /0x[a-fA-F0-9]{40}/g.test(v) || 'This is now a valid wallet address.';
      }]
    },
    model: {
      value: _vm.addressToWhitelist,
      callback: function callback($$v) {
        _vm.addressToWhitelist = $$v;
      },
      expression: "addressToWhitelist"
    }
  }), _c('v-text-field', {
    attrs: {
      "required": "",
      "color": "orange",
      "label": "Your Email Address",
      "rules": [function (v) {
        return !!v || 'This field is required';
      }, function (v) {
        return /.+@.+\..+/.test(v) || 'Must be a valid email.';
      }]
    },
    model: {
      value: _vm.emailAddress,
      callback: function callback($$v) {
        _vm.emailAddress = $$v;
      },
      expression: "emailAddress"
    }
  })], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    staticClass: "white--text px-8",
    attrs: {
      "color": "orange",
      "disabled": !_vm.isFormValid || _vm.disableButton,
      "large": ""
    },
    on: {
      "click": function click($event) {
        return _vm.request_lbank_whitelist();
      }
    }
  }, [_vm._v(" Request Whitelist ")])], 1)], 1)], 1)], 1)], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }