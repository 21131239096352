var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', [_c('span', {
    staticClass: "bg",
    attrs: {
      "id": "bgImage"
    }
  }), _c('v-app-bar', {
    attrs: {
      "absolute": "",
      "color": _vm.barTransparent,
      "src": _vm.backgroundSRC,
      "elevation": "0"
    }
  }, [_c('v-spacer'), _c('v-app-bar-title', [_c('router-link', {
    attrs: {
      "to": "/"
    }
  }, [_vm.$route.path != '/' ? _c('img', {
    staticStyle: {
      "display": "block",
      "margin": "auto"
    },
    attrs: {
      "src": "/img/curepay-logo-small-dark.png",
      "height": "45",
      "width": "auto",
      "alt": "CURE Token Logo"
    }
  }) : _vm._e()])], 1), _c('v-spacer'), _c('v-menu', {
    attrs: {
      "offset-y": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_vm.$route.path == '/' ? _c('v-btn', _vm._g({
          attrs: {
            "dark": "",
            "icon": ""
          }
        }, on), [_c('v-icon', [_vm._v("mdi-dots-vertical")])], 1) : _c('v-btn', _vm._g({
          attrs: {
            "light": "",
            "icon": ""
          }
        }, on), [_c('v-icon', [_vm._v("mdi-dots-vertical")])], 1)];
      }
    }])
  }, [_c('v-card', [_c('v-list', {
    attrs: {
      "dense": ""
    }
  }, [!_vm.$auth.isAuthenticated ? _c('v-list-item', {
    attrs: {
      "link": ""
    },
    on: {
      "click": _vm.login
    }
  }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("mdi-login")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v("Login to My Account")])], 1)], 1) : _vm._e(), _vm.$auth.isAuthenticated ? _c('v-list-item', {
    attrs: {
      "link": ""
    },
    on: {
      "click": _vm.logout
    }
  }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("mdi-logout")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v("Logout")])], 1)], 1) : _vm._e(), _vm.$route.path == '/' && _vm.$auth.isAuthenticated ? _c('v-list-item', {
    attrs: {
      "link": ""
    },
    on: {
      "click": _vm.login
    }
  }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("mdi-view-dashboard")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v("Back to My Account")])], 1)], 1) : _vm._e()], 1)], 1)], 1)], 1), _vm.$route.path == '/' ? _c('v-card', {
    staticClass: "overflow-hidden",
    attrs: {
      "tile": "",
      "color": "black",
      "elevation": "0"
    }
  }, [_c('transition', {
    attrs: {
      "name": "fade",
      "mode": "out-in"
    }
  }, [_c('router-view')], 1)], 1) : _c('v-card', {
    staticClass: "overflow-hidden",
    attrs: {
      "tile": "",
      "color": "transparent",
      "elevation": "0"
    }
  }, [_c('v-sheet', {
    staticStyle: {
      "margin-top": "100px",
      "margin-bottom": "100px",
      "background": "none",
      "min-height": "400px"
    }
  }, [_c('transition', {
    attrs: {
      "name": "fade",
      "mode": "out-in"
    }
  }, [_c('router-view')], 1)], 1)], 1), _c('v-footer', {
    staticClass: "pb-12 ma-5",
    attrs: {
      "dark": "",
      "padless": "",
      "color": "transparent",
      "elevation": "0"
    }
  }, [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" " + _vm._s(new Date().getFullYear()) + " — "), _c('strong', [_vm._v("CURE Token | The Beckley Foundation")]), _c('br'), _c('a', {
    staticClass: "white--text",
    attrs: {
      "href": "https://www.curetoken.net/",
      "target": "_blank"
    }
  }, [_vm._v("www.curetoken.net")]), _vm._v(" | "), _c('a', {
    staticClass: "white--text",
    attrs: {
      "href": "/support"
    }
  }, [_vm._v("Need Support?")])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }