<template>
  <v-card class="text-center">
    <v-card-text >
      <h2 class="my-10">UNDER MAINTENANCE</h2>
      <!--<v-text-field
        v-model="purchaseAmount"
        prepend-inner-icon="mdi-currency-usd"
        height="100"
        style="
          width: fit-content;
          block-size: fit-content;
          margin: 0 auto;
          font-size: 100px;
          font-weight: bold;
          text-align: center;"
        color="orange"
        :class="`pb-4 custom-placeholder-color`"
        id="purchaseInput"
        placeholder="50"
        clearable
        type="number"
        hide-spin-buttons
        required
        oninput="if(this.value.length > Number(4)) this.value = this.value.slice(0, 4);"
        v-on:keypress="isNumber($event)"
        :rules="[
          v => +v >= 50 || '$50 Minimum',
          v => +v <= 5000 || '$5,000 Maximum'
        ]"
      ></v-text-field>
      <p>
        <strong id="totalCure" style="font-size: 18px;">
          ~{{numberWithCommas(tokenAmount.toFixed(2))}} CURE
        </strong>
      </p>
      <p class="py-5 my-7" style="border: 1px #f1f1f1 solid; line-height: 150%;">
        <table style="width:90%; margin: auto;">
          <tr>
            <td style="text-align: left">CURE Current Price</td>
            <td style="text-align: right">${{currentPrice}}</td>
          </tr>
          <tr>
            <td style="text-align: left">Credit Card Processing Fees</td>
            <td style="text-align: right">{{processingFees}}%</td>
          </tr>
          <tr>
            <td class="font-weight-bold" style="text-align: left">Total Value</td>
            <td class="font-weight-bold" style="text-align: right">
              ${{ numberWithCommas((tokenAmount * currentPrice).toFixed(2)) }}
            </td>
          </tr>
        </table>
      </p>
      <p class="pt-2 text-left" style="font-size: 10px; line-height: 1.5em;">
        <v-checkbox
          v-model="checked"
          :rules="[v => !!v || 'You must agree in order to continue.']"
          label="Agree to the terms and conditions"
          required
        ></v-checkbox>
        {{terms}}
      </p>
      <v-btn
        class="white--text"
        color="orange"
        x-large
        block
        :disabled="purchaseAmount === '' || clicked === true ||
          currentPrice === 0 || +purchaseAmount < 50 ||
          +purchaseAmount > 5000 || !checked"
        @click="purchase"
      >
        CHECKOUT NOW
      </v-btn>
      <div v-show="currentPrice > 0 && +purchaseAmount >= 500000 &&
          +purchaseAmount <= 5000 && checked">
        <div class="my-10">&mdash; or &mdash;</div> 
        <div id="paypal-button" />
      </div>
      <p style="font-size: 10px; line-height: 1.5em;">Purchasing and selling tokens is inherently risky and holders must bear their risk of loss. Neither the authors of this website nor any participants in the CURE Token project accept any liability for losses or taxes that holders, purchasers or sellers of CURE Token may incur. The value of CURE Token may decrease and may be highly volatile.</p>
      <p>This website is not directed towards any person located in a jurisdiction where purchasing, selling or holding CURE Token is prohibited or regulated. Consult your local laws before transacting in any cryptocurrency.</p>
      <v-btn
        class="white--text md-elevation-0"
        style="box-shadow: none;"
        color="white"
        small
        block
        flat
        @click="purchase"
      >
        &nbsp;
      </v-btn>-->
    </v-card-text>
  </v-card>
</template>

<script>
import axios from 'axios';
import PayPal from 'vue-paypal-checkout'

export default {
  data() {
    return {
      price: [],
      polling: null,
      purchaseAmount: '',
      checked: false,
      clicked: false,
      terms: 'Transaction fees apply. Approximate price and value subject to change during the purchase process. Access to your tokens is limited for a period of time, until a mandatory KYC is performed. After this point, you can request your private keys within your secure dashboard. By investing in any cryptocurrenty, understand that your investment can go up or down.',
      currentPrice: 0,
      processingFees: 5,
      transactionFees: 10,
      credentials: {
        sandbox: 'AViev34t2tORZy7myUsykqI-NWjUl1936pcNvkFlSHXEMfk0zWuc4d_cIhWZxu8UzuYzBLf5CWUDth9S',
        production: 'AViev34t2tORZy7myUsykqI-NWjUl1936pcNvkFlSHXEMfk0zWuc4d_cIhWZxu8UzuYzBLf5CWUDth9S',
      },
      experienceOptions: {
        input_fields: {
          no_shipping: 1
        }
      },
      myStyle: {
        size:  'responsive',
        shape: 'rect',
        color: 'blue'
      }
    };
  },
  watch: {
    purchaseAmount(val) {
      //this.purchaseAmount = val.replace('.','');
      if(val.charAt(0) === '0')
      {
        this.purchaseAmount = val.substring(1);
      }
    },
  },
  computed: {
    tokenAmount() {
      return (
        (+this.purchaseAmount / this.currentPrice)
        * (1 - this.processingFees / 100)
      ) || 0;
    },
  },
  methods: {
    isNumber(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if(/^[0-9]+$/.test(char)) return true; // Match with regex 
      else e.preventDefault(); // If not match, don't add to input text
    },
    numberWithCommas(string) {
      return string.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    purchase(){
      this.clicked=true;
      this.$emit('purchase', this.purchaseAmount);
    },
    get_current_price() {
      const path = `${this.$domainPath}/get-current-price`;
      console.log(path);
      axios.get(path)
        .then((res) => {
          this.price = res.data.price;
          console.log(this.price);
          this.currentPrice = this.price;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    onApprove(data, actions) {
      return actions.order.capture().then(details => {
        console.log(details);
        this.$router.push({ path: '/', query: { id: details.id }})
        location.reload();
      });
    },
  },
  created() {
    this.get_current_price();
  },
  mounted() {
    function loadScript(url, callback) {
      const el = document.querySelector(`script[src="${url}"]`);
      if (!el) {
        const s = document.createElement('script');
        s.setAttribute('src', url); s.onload = callback;
        document.head.insertBefore(s, document.head.firstElementChild);
      }
    }

    loadScript('https://www.paypal.com/sdk/js?client-id=AW8MX-tyk7BateicowIwNtZlqFaG3qq_H0LSXYKBR3kM3YG3EORQOyN6KUhklTFedMOg6DsaPgMhSeSW&currency=USD&disable-funding=credit&enable-funding=venmo', () => {
      paypal.Buttons({
        style: {
          layout:  'vertical',
          color:   'blue',
          shape:   'rect',
          label:   'paypal'
        },
        // Set up the transaction
        createOrder(data, actions) {
          return actions.order.create({
            purchase_units: [{
              amount: {
                value: document.getElementById("purchaseInput").value,
              },
            }],
          });
        },
        onApprove:this.onApprove,
        onError: function (err) {
          console.log(err);
        },
        onCancel: function (data) {
          console.log(data);
        }
      }).render('#paypal-button');
    });
  },
};
</script>

<style>
  .v-input input {
    max-height: 95px;
    text-align: center;
  }
  .v-text-field input {
    padding: 0;
  }
  .v-text-field__slot {
    padding-bottom: 25px;
  }

  .custom-placeholder-color input::placeholder {
    opacity: .25;
  }

  #totalCure{
    float: right;
    margin-top: -25px;
  }

</style>
