var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', {
    staticClass: "overflow-y-auto"
  }, [_c('v-parallax', {
    attrs: {
      "fill-height": "",
      "dark": "",
      "src": "/img/bg-orange.jpg",
      "height": "600"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-slide-y-transition', [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.show,
      expression: "show"
    }]
  }, [_c('img', {
    staticClass: "mb-10",
    staticStyle: {
      "display": "block",
      "margin": "auto"
    },
    attrs: {
      "src": "/img/curepay-logo-vertical-small.png",
      "height": "100",
      "width": "auto",
      "alt": "CURE Token Logo"
    }
  }), _c('h1', {
    staticClass: "text-h2 font-weight-thin mt-12"
  }, [_vm._v(" Buying crypto is difficult. ")]), _c('h2', {
    staticClass: "my-4 font-weight-light"
  }, [_vm._v(" That’s why we’ve made it easy. Use traditional payment methods and secure your piece of CURE. ")])])])], 1)], 1)], 1), _c('v-main', {
    staticClass: "my-12"
  }, [_c('v-container', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-sheet', {
    attrs: {
      "rounded": "sm",
      "min-height": "268"
    }
  }, [_c('v-container', [_c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "sm": "11"
    }
  }, [_c('v-stepper', {
    attrs: {
      "elevation": 20
    },
    model: {
      value: _vm.currentStep,
      callback: function callback($$v) {
        _vm.currentStep = $$v;
      },
      expression: "currentStep"
    }
  }, [_c('v-stepper-header', [_vm._l(_vm.steps, function (step, index) {
    return [_c('v-stepper-step', {
      key: "".concat(step.title, "-step"),
      attrs: {
        "complete": _vm.currentStep > index + 1,
        "step": index + 1,
        "color": "orange"
      }
    }, [_vm._v(" " + _vm._s(step.title) + " ")]), index + 1 !== _vm.steps.length ? _c('v-divider', {
      key: step.title
    }) : _vm._e()];
  })], 2), _c('v-stepper-items', _vm._l(_vm.steps, function (step, index) {
    return _c('v-stepper-content', {
      key: "".concat(step.title, "-content"),
      attrs: {
        "step": index + 1
      }
    }, [_c(step.component, {
      tag: "component",
      on: {
        "purchase": function purchase($event) {
          return _vm.purchaseCURE($event);
        },
        "complete": function complete($event) {
          _vm.currentStep = 3;
        }
      }
    })], 1);
  }), 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "order-sm": "first",
      "order-md": "first"
    }
  }, [_c('v-sheet', {
    staticClass: "ma-4",
    attrs: {
      "min-height": "50vh",
      "rounded": "lg"
    }
  }, [_c('v-flex', [_c('h2', [_vm._v("Introducing CURE Pay.")]), _c('p', {
    staticClass: "pr-10"
  }, [_vm._v(" The easy way to buy "), _c('a', {
    attrs: {
      "href": "https://www.curetoken.net/",
      "target": "_blank"
    }
  }, [_vm._v("CURE Token")]), _vm._v(", and support the cause. We handle everything in real-time, so that you don’t have to. Once completed, you will have your own secure dashboard to access your tokens. "), _c('br'), _c('br'), _vm._v(" The result is that you have an easy to manage place to store your CURE. If at any point you want to sell, we provide you with online educational resources to help with that process. ")]), _c('v-container', {
    staticClass: "mb-8",
    staticStyle: {
      "max-width": "600px"
    }
  }, [_c('v-timeline', {
    attrs: {
      "dense": "",
      "clipped": ""
    }
  }, [_c('v-timeline-item', {
    attrs: {
      "color": "orange",
      "icon-color": "grey lighten-2",
      "large": "",
      "fill-dot": ""
    },
    scopedSlots: _vm._u([{
      key: "icon",
      fn: function fn() {
        return [_c('span', {
          staticClass: "white--text font-weight-black headline"
        }, [_vm._v("1")])];
      },
      proxy: true
    }])
  }, [_c('v-row', {
    attrs: {
      "justify": "space-between"
    }
  }, [_c('v-col', {
    staticClass: "mt-6",
    attrs: {
      "cols": "12"
    }
  }, [_c('h3', [_vm._v("Choose An Amount")]), _c('span', {
    staticClass: "body-2"
  }, [_vm._v("Enter the amount of USD that you would like to buy.")])])], 1)], 1), _c('v-timeline-item', {
    attrs: {
      "color": "orange",
      "icon-color": "grey lighten-2",
      "large": "",
      "fill-dot": ""
    },
    scopedSlots: _vm._u([{
      key: "icon",
      fn: function fn() {
        return [_c('span', {
          staticClass: "white--text font-weight-black headline"
        }, [_vm._v("2")])];
      },
      proxy: true
    }])
  }, [_c('v-row', {
    attrs: {
      "justify": "space-between"
    }
  }, [_c('v-col', {
    staticClass: "mt-6",
    attrs: {
      "cols": "12"
    }
  }, [_c('h3', [_vm._v("Enter Payment Details")]), _c('span', {
    staticClass: "body-2"
  }, [_vm._v("You can buy via credit card, debit card, and even Apple Pay.")])])], 1)], 1), _c('v-timeline-item', {
    attrs: {
      "color": "orange",
      "icon-color": "grey lighten-2",
      "large": "",
      "fill-dot": ""
    },
    scopedSlots: _vm._u([{
      key: "icon",
      fn: function fn() {
        return [_c('span', {
          staticClass: "white--text font-weight-black headline"
        }, [_vm._v("3")])];
      },
      proxy: true
    }])
  }, [_c('v-row', {
    attrs: {
      "justify": "space-between"
    }
  }, [_c('v-col', {
    staticClass: "mt-6",
    attrs: {
      "cols": "12"
    }
  }, [_c('h3', [_vm._v("Receive Your Tokens")]), _c('span', {
    staticClass: "body-2"
  }, [_vm._v("You will get a confirmation email with your token.")])])], 1)], 1)], 1)], 1), _c('h2', [_vm._v("About CURE Token")]), _c('p', {
    staticClass: "pr-10"
  }, [_vm._v(" CURE is dedicated to using the power of decentralized finance to help rid the planet of dreadful diseases like cancer. Partnering with global research teams and drawing from a wealth of experience in the innovation, technological, and charitable sectors, CURE aims to go one step further than simply becoming the world's most successful charity token. Learn more at "), _c('a', {
    attrs: {
      "href": "https://www.curetoken.net/",
      "target": "_blank"
    }
  }, [_vm._v("www.curetoken.net")])])], 1)], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }