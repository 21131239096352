<template>
  <v-container>
    <template>
      <v-row justify="center">
        <v-dialog
          v-model="transferAlert"
          persistent
          max-width="500"
        >
          <v-card class="pa-4">
            <v-card-title class="text-h5">
              Sure, we can help you with that.
            </v-card-title>
            <v-card-text>Please reach out to <a href='mailto:support@curetoken.net'>support@curetoken.net</a> to request support on how to sell your tokens. Alternatively, you can request your private keys from the Pro Features Mode and import them into a software wallet of your choice.</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="white--text"
                color="orange"
                large
                @click="transferAlert = false"
              >
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
    <div class="text-center">

      <v-dialog
        v-model="requestProFeatures"
        max-width="500px"
        class="pa-8"
      >
        <v-card class="pa-8">
          <v-card-text class="pa-0">
            <v-row class="">
              <v-col sm="12" cols="12" class="pa-8">
                <h2>
                  Request With KYC
                </h2>
                <h4 class="mb-4">
                  Approximately 1-5 Minutes
                </h4>
                <p>This feature will allow you to sell, transfer and obtain your private keys. You can enable this feature by completing a KYC that requires you to provide your ID for validation.</p>
                <v-btn
                  color="orange"
                  class="white--text"
                  elevation="0"
                  block
                  large
                  @click="request_kyc()"
                >
                  Perform KYC <!--Perform KYC -->
                </v-btn>
              </v-col>

            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="transferForm"
        persistent
        max-width="500px"
      >
        <v-card class="pa-4" id="transferHolder">
          <v-card-title>
            <span class="text-h5">Transfer Your Tokens</span>
          </v-card-title>
          <v-card-text>
            <p class="">You can now transfer your tokens to a wallet of your choice without a 10% tax, just cover the transaction fee.</p>
            <p class="red--text">WARNING: This will tranfer all of your CURE Tokens to this wallet. Be certain that this is accurate as there is no way to recover your tokens.</p>
            <v-form v-model="isFormValid">
              <v-text-field
                label="BEP-20 Wallet Address"
                required
                color="orange"
                class="mt-4"
                id="walletAddress"
                v-model="transferToWallet"
                :rules="[v => !!v || 'This field is required', v => /0x[a-fA-F0-9]{40}/g.test(v)||'This is now a valid wallet address.']"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="grey"
              text
              large
              @click="transferForm = false"
            >
              Cancel
            </v-btn>
            <v-btn
              color="orange"
              class="white--text px-4"
              :disabled="!isFormValid"
              large
              @click="transferTokens()"
            >
              Transfer Tokens
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <v-row justify="center" >
      <v-col cols="12">
        <!--<v-alert
          border="bottom"
          colored-border
          type="error"
          elevation="2"
        >
          CURE Pay is currently undergoing maintenance, some of the features may be disabled or results may be inaccurate.
        </v-alert>-->
        <v-alert
          border="bottom"
          colored-border
          type="info"
          elevation="2"
        >
          The 0% transfer feature is now active. You can transfer all of your tokens to a wallet of your choice. In order to use this feature, you must complete a KYC by clicking on Request Pro Features.
        </v-alert>
        <v-alert
          border="bottom"
          colored-border
          type="error"
          elevation="2"
        >
          The crypto space is filled with frauds, scams, and people that wants to take your tokens. Be sure to only interact with <a href='mailto:support@curetoken.net'>support@curetoken.net</a> and <a href='https://t.me/jacobbeckley' target='_blank'>@jacobbeckley</a> on Telegram.
        </v-alert>
      </v-col>
    </v-row>
    <v-row justify="center" >
      <v-col sm="4" cols="12">
        <v-row justify="center">
          <v-fade-transition>
            <v-sheet
              rounded
              width="100%"
              elevation="5"
              style="padding:20px; opacity: 0; position: relative;"
              class="mb-4 mx-4 animated fadeIn"
            > 
            <v-progress-circular
              :size="40"
              :width="5"
              color="grey lighten-3"
              indeterminate
              class="mb-4 mx-4 animated loader"
              v-bind:class="{ fadeOut: show }"
            ></v-progress-circular>
              <div style="opacity: 0" class="animated" v-bind:class="{ fadeIn: show }" >
                <v-list-item class="mb-6 ma-0 pa-0">
                <v-avatar
                  color="orange" 
                  size="65"
                  class="profile-picture white--text"
                >
                  <h1>{{ user.initials }}</h1>
                </v-avatar>
                <v-list-item-content>
                  <v-list-item-subtitle>
                      <div class="ma-4">
                        <h2 class="grey--text text--darken-1">{{ user.fullname }}</h2>
                        <p class="grey--text text--darken-1 text-wrap caption">{{ shortenLength(email) }}</p>
                      </div>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item class="mt-8">
                <v-list-item-content style="text-align: center;">
                  <v-list-item-title class="grey--text text--darken-1">
                    TOTAL CURE OWNED
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <h1 class='orange--text font-weight-light'>{{numberWithCommas(currentBalance.toFixed(3))}}</h1>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="mb-8">
                <v-list-item-content style="text-align: center;">
                  <v-list-item-title class="grey--text text--darken-1">
                    CURRENT CURE PRICE
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <h1 class='orange--text font-weight-light'>${{ (currentPrice) }}</h1>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item class="mt-8">
                <v-list-item-content style="text-align: center;" class="py-2">
                  <v-list-item-subtitle>
                    <v-btn
                      class="white--text py-0"
                      color="orange"
                      block
                      large
                      @click="buy_tokens()"
                    >BUY Tokens</v-btn>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="py-0">
                <v-list-item-content style="text-align: center;" class="py-2">
                  <v-list-item-subtitle>
                    <v-btn
                      class="white--text"
                      color="grey"
                      block
                      large
                      :disabled="currentBalance <= 0"
                      @click="transferAlert = true;"
                    >Sell Tokens</v-btn>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <!--<v-list-item class="py-0">
                <v-list-item-content style="text-align: center;" class="py-2">
                  <v-list-item-subtitle>
                    <v-btn
                      class="white--text"
                      color="grey"
                      block
                      large
                      href="https://staking.curetoken.net" 
                      target="_blank"
                    >Stake My Tokens</v-btn>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>-->
              

              <div v-if="proMode">
                <v-list-item class="">
                  <v-list-item-content style="text-align: center;" class="py-2 mb-8">
                    <v-list-item-subtitle>
                      <v-btn
                        class="white--text"
                        color="grey"
                        block
                        large
                        :disabled="currentBalance <= 0"
                        @click="transfer_modal()"
                      >Transfer Tokens</v-btn>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <!--<v-divider></v-divider>
                <v-list-item class="my-6">
                  <v-list-item-content style="text-align: center;">
                    <v-list-item-title class="grey--text text--darken-1">
                      WALLET ADDRESS
                    </v-list-item-title>
                    <v-list-item-subtitle class="caption orange--text font-weight-light">
                      {{ shortenLength(walletAddress) }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>-->

              </div>
            </div>
            </v-sheet>
          </v-fade-transition>
        </v-row>
      </v-col>
      <v-col sm="8" cols="12" class="">
        <v-row justify="center">
          <v-sheet
            rounded
            width="100%"
            elevation="5"
            style="padding:20px; opacity: 0; position: relative;"
            class="mx-4 animated fadeIn"
          >
          <v-progress-circular
              :size="40"
              :width="5"
              color="grey lighten-3"
              indeterminate
              class="mb-4 mx-4 animated loader"
              v-bind:class="{ fadeOut: show }"
            ></v-progress-circular>
          <div style="opacity: 0" class="animated" v-bind:class="{ fadeIn: show }">
          <v-card
            class="mx-auto"
            transparent
            elevation="0"
          >
            
            <v-container v-if="!proMode">
              <v-row justify="end" class="mb-2">
                
                    <v-btn
                      class="white--text"
                      color="grey"
                      small
                      elevation="0"
                      :disabled="kycButtonDisabled"
                      @click="requestProFeatures = true"
                    >
                      <v-icon left>
                        mdi-check-decagram
                      </v-icon>
                      {{ kycButtonText }}
                    </v-btn>

              </v-row> 
            </v-container>
            <v-divider class="mb-4" v-if="!proMode"></v-divider>
            
            <v-card-title>
              <v-row align="start">

                <div class="text-caption grey--text text-uppercase">
                  <p class="grey--text text--darken-1 ml-1 mb-0">Current Price
                    <span class="" v-if="difference.includes('-')">
                    <span
                      v-text="difference || '-'"
                      class="red--text"
                    ></span>
                    <v-icon small class="red--text">mdi-menu-down</v-icon>
                  </span>
                  <span class="" v-else>
                    <span
                      v-text="difference || '-'"
                      class="green--text"
                    ></span>
                    <v-icon small class="green--text">mdi-menu-up</v-icon>
                  </span>
                  </p>
                  <span
                    style="line-height: .8em;"
                    class="text-h3 orange--text font-weight-light mt-0"
                    v-text="currentPrice || '—'"
                  ></span>
                </div>
              <v-spacer></v-spacer>
              <span class="text-caption grey--text text-uppercase text-right">
                <span class="d-block red--text">LOW: {{ minNum.toFixed(6) }} </span>
                <span style="line-height:.9em" class="d-block green--text">HIGH: {{ maxNum.toFixed(6) }}</span>
              </span>
              </v-row>
              
            </v-card-title>

            <v-sheet color="transparent">
              <div>
                <v-sparkline
                :value="value"
                :gradient="gradient"
                :smooth="radius || false"
                :padding="padding"
                :line-width="width"
                :stroke-linecap="lineCap"
                :gradient-direction="gradientDirection"
                :fill="fill"
                :type="type"
                :auto-line-width="autoLineWidth"
                auto-draw
                height="70px"
                color="grey"
                ref="sparkline"
            >
              <template v-slot:label="item">
                {{ Number(item.value).toFixed(4) }}
              </template>
            </v-sparkline>
          </div>
            </v-sheet>
          </v-card>
          <v-divider></v-divider>
          <div v-if="this.$router.app.$auth.user['https://pay.curetoken.net/roles'][0] === 'admin'">

            <v-form>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      v-model="nftEmail"
                      label="E-mail"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      v-model="nftNumber"
                      label="number"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      v-model="nftTxid"
                      label="txid"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      v-model="stripeId"
                      label="stripeId"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-btn
                    color="orange"
                    class="white--text px-4"
                    large
                    @click="sendEmail()"
                  >
                    Send Email
                  </v-btn>
                  
                </v-row>
              </v-container>
            </v-form>
          </div>
          <div v-if="this.$router.app.$auth.user['https://pay.curetoken.net/roles'][0] === 'admin'">
            <h2 class="mt-6 grey--text text--darken-1 font-weight-light">Admin Tools</h2>
             <v-divider></v-divider>
             <v-sheet class="text-center my-8 grey lighten-4" >
                <v-row class="text-center pa-4" >
                <v-col sm="3" md="3" lg="3" cols="12">
                  <h5 class="grey--text text--darken-1 font-weight-light">TOTAL TRANSACTIONS</h5>
                  <h2 class='orange--text font-weight-light' style="line-height: 0.9em;">{{ this.totalTransactions }}</h2>
                </v-col>
                <v-col sm="3" md="3" lg="3" cols="12">
                  <h5 class="grey--text text--darken-1 font-weight-light">TOTAL USERS</h5>
                  <h2 class='orange--text font-weight-light' style="line-height: 0.9em;">{{ this.activeUsers }}</h2>
                </v-col>
                <v-col sm="3" md="3" lg="3" cols="12">
                  <h5 class="grey--text text--darken-1 font-weight-light">TOTAL USD</h5>
                  <h2 class='orange--text font-weight-light' style="line-height: 0.9em;">${{ numberWithCommas(this.usdYTD) }}</h2>
                  <h5 class="grey--text text--darken-1 font-weight-light"><span class="font-weight-bold">${{ numberWithCommas(this.usdWTD) }}</span> TODAY</h5>
                </v-col>
                <v-col sm="3" md="3" lg="3" cols="12">
                  <h5 class="grey--text text--darken-1 font-weight-light">TOTAL CURE</h5>
                  <h2 class='orange--text font-weight-light' style="line-height: 0.9em;">{{ numberWithCommas(this.cureYTD)}}</h2>
                  <h5 class="grey--text text--darken-1 font-weight-light"><span class="font-weight-bold">{{ numberWithCommas(this.cureWTD) }}</span> TODAY</h5>
                </v-col>
              </v-row>
            </v-sheet>
            <v-divider></v-divider>
          </div>
          
          <div v-if="this.transferData != 0">
            <h2 class="mt-6 grey--text text--darken-1 font-weight-light">Transfer Summary</h2>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left orange--text text--lighten-1" style="max-width:50px;">
                      DATE
                    </th>
                    <th class="text-left orange--text text--lighten-1" style="max-width:50px;">
                      TOTAL CURE
                    </th>
                    <th class="text-left orange--text text--lighten-1">
                      ADDRESS
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in transferData"
                    :key="item[0]"
                  >
                    <td class="text-left grey--text text--darken-1" style="max-width:50px;">{{ formatDate(item[0]) }}</td>
                    <td class="text-left grey--text text--darken-1" style="max-width:50px;">{{ numberWithCommas(item[3]) }}</td>
                    <td class="text-left grey--text text--darken-1">{{ item[2] }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>

          <h2 class="mt-6 grey--text text--darken-1 font-weight-light">Purchase Summary</h2>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left orange--text text--lighten-1">
                    DATE
                  </th>
                  <!--<th class="text-left">
                    WALLET
                  </th>-->
                  <th class="text-left orange--text text--lighten-1">
                    TOTAL CURE
                  </th>
                  <th class="text-left orange--text text--lighten-1">
                    TOTAL USD
                  </th>
                  <th class="text-left orange--text text--lighten-1">
                    CURE PRICE
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in userData"
                  :key="item[0]"
                >
                  <td class="text-left grey--text text--darken-1">{{ formatDate(item[0]) }}</td>
                  <!--<td><span class="text-truncate truncate" style="min-width: 100px;">{{ item[7] }}</span></td>-->
                  <td class="text-left grey--text text--darken-1">{{ numberWithCommas(item[4]) }}</td>
                  <td class="text-left grey--text text--darken-1">${{ addDecimal(item[3]) }}</td>
                  <td class="text-left grey--text text--darken-1"><span v-text="item[9] || '—'"></span></td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
        </v-sheet>
      </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<style>
  .summary{
    line-height: 1.5em;
  }
  .truncate {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  #transferHolder .v-text-field__slot {
    padding-top:10px !important;
    padding-bottom:10px !important;
  }
  table {
    width: 100%;
  }

  td {
    max-width: 0;
    overflow: hidden;
    padding: 5px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .animated {
    background-repeat: no-repeat;
    background-position: left top;
    -webkit-animation-duration: .5s;
    animation-duration: .5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
   
  @-webkit-keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: 1;}
  }
   
  @keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: 1;}
  }
   
  .fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
  }

  @-webkit-keyframes fadeOut {
    0% {opacity: 1;}
    100% {opacity: 0;}
  }
   
  @keyframes fadeOut {
    0% {opacity: 1;}
    100% {opacity: 0;}
  }
   
  .fadeOut {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
  }

  .loader {
    display: block;
    margin: 0 auto;
    position: absolute;
    left: calc(50% - 40px);
    top: calc(50% - 40px);
  }

  svg > g > text {
    font-size: 0.5em;
  }

  .grayscale {
    filter: grayscale(100%) brightness(.3);
    transition: .5s;
  }

  .no-grayscale {
    filter: grayscale(0%) brightness(1);
    transition: .5s;
  }

</style>
<script>
import axios from 'axios';

const gradients = [
  ['#ffb300', '#ffb300', '#ffb300', '#fe0001',],
]

export default {
  watch:{
    proMode(){
      var element = document.getElementById("bgImage");
      this.changeMode();
    }
  },
  computed: {
    tokenAmount() {
      return (
        (+this.currentBalance * this.currentPrice)
      ) || 0;
    },
    curePrice(item) {
      return "$"+ this.addDecimal(this.curePriceAtTime.toFixed(6));
    }
  },
  methods: {
    async callApi() {
      const token = await this.$auth.getTokenSilently();
      fetch(`${this.$domainPath}/test_auth`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`
        },
      })
        .then((result) => result.json())
        .then((res) => {
          
        });
    },
    changeMode(){
      var element = document.getElementById("bgImage");
      if(this.proMode){
        element.classList.add("grayscale");
        element.classList.remove("no-grayscale");
        //this.set_mode("verified");
      } else {
        element.classList.add("no-grayscale");
        element.classList.remove("grayscale");
        //this.set_mode("standard");
      }
    },
    buy_tokens() {
      this.$router.push('/');
    },
    async transferTokens() {
      this.transferForm = false;
      const path = `${this.$domainPath}/transfer-tokens`;
      const token = await this.$auth.getTokenSilently();
      fetch(path, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ transferToWallet: this.transferToWallet, email: this.email  }),
      })
        .then((result) => result.json())
        .then((res) => {
          //console.log(res);
          this.get_token_balance(this.walletAddress);
          this.get_transfer_data(this.email);
        });
    },
    transfer_modal() {
      this.transferForm = true;
    },
    shortenLength(string) {
      if(window.innerWidth > 1200) {
        return string
      }
      return string.substring(0,25) + "..."
    },
    numberWithCommas(string) {
      let partArray = string.split(".");
      if(partArray.length > 1) {
        let leftPart = partArray[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        let rightPart = partArray[1]
        rightPart = rightPart.substring(0,3);
        return leftPart + "." + rightPart;
      }else{
        return string;
      }      
    },
    addDecimal(string) {
      return string;
    },
    formatDate(dateString) {
      let dateFormat = new Date(dateString).toUTCString();
      dateFormat = dateFormat.split(' ').slice(1, 4).join(' ');
      return dateFormat;
    },
    get_current_price() {
      const path = `${this.$domainPath}/get-current-price`;
      axios.get(path)
        .then((res) => {
          this.price = res.data.price;
          this.currentPrice = this.price;
        })
        .catch((error) => {
          
        });
    },
    async get_token_balance(walletAddress) {
      const path = `${this.$domainPath}/get-token-balance`;
      const token = await this.$auth.getTokenSilently();
      fetch(path, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ wallet_address: walletAddress }),
      })
        .then((result) => result.json())
        .then((res) => {
          if (res.status == "success") {
            this.currentBalance = Number(res.total);
          }          
        });
    },
    async get_user(userEmail) {
      this.user = {
        "firstname": " ",
        "fullname": "...",
        "initials": "",
        "lastname": "",
        "status": ""
      }
      const token = await this.$auth.getTokenSilently();
      const path = `${this.$domainPath}/get-user`;
      fetch(path, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ email: userEmail }),
      })
        .then((result) => result.json())
        .then((res) => {
          
          if(this.$router.app.$auth.user['https://pay.curetoken.net/roles'][0] === '_pf') {
            this.proMode = true;
            this.requestedPro = false;
            this.requestProFeatures = false;
          }else{
            this.proMode = false;
            this.requestedPro = true;
            this.requestProFeatures = false;
          }

          this.user = res;
          setTimeout(() => { this.show = true; }, 0);
        });
    },
    async get_user_data(userEmail) {
      const path = `${this.$domainPath}/get-user-data`;
      const token = await this.$auth.getTokenSilently();
      fetch(path, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ email: userEmail }),
      })
        .then((result) => result.json())
        .then((res) => {
          this.userData = res;
        });
    },
    async get_transfer_data(userEmail) {
      const path = `${this.$domainPath}/get-transfer-data`;
      const token = await this.$auth.getTokenSilently();
      fetch(path, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ email: userEmail }),
      })
        .then((result) => result.json())
        .then((res) => {
          console.log(res)
          if(res.length <= 0) {
            this.transferData = 0;
          } else {
            this.transferData = res;
          }
          
        });
    },
    async sendEmail() {
      const path = `${this.$domainPath}/send-nft-email`;
      const token = await this.$auth.getTokenSilently();
      fetch(path, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ email: this.email, nftEmail: this.nftEmail, nftNumber: this.nftNumber, nftTxid: this.nftTxid, stripeId: this.stripeId }),
      })
        .then((result) => result.json())
        .then((res) => {
          

          if (res.status == "success") {
            console.log("Email Sent");
            alert("Email Sent");
          } else {
            console.log("Email Error");
            alert("Email Error");
          }
        });
    },
    async get_user_wallet(userEmail) {
      const path = `${this.$domainPath}/get-user-wallet`;
      const token = await this.$auth.getTokenSilently();
      fetch(path, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ email: userEmail }),
      })
        .then((result) => result.json())
        .then((res) => {
          this.walletAddress = String(res);
          this.get_token_balance(this.walletAddress)
        });
    },
    async get_chart_data(timeframe) {
      const token = await this.$auth.getTokenSilently();
      const path = `${this.$domainPath}/get-chart-data`;
      fetch(path, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ timeframe: timeframe }),
      })
        .then((result) => result.json())
        .then((res) => {
          this.maxNum = Math.max(...res.data);
          this.minNum = Math.min(...res.data);
          this.difference = res.difference;
          setTimeout(() => { this.value = res.data; }, 0);
        });
    },
    async request_kyc() {
      const stripe = Stripe(process.env.VUE_APP_STRIPE_KEY);
      
      this.requestProFeatures = false;
      this.kycButtonDisabled = true;

      const token = await this.$auth.getTokenSilently();
      const path = `${this.$domainPath}/request-kyc`;
      const t = this;
      fetch(path, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ email: this.email }),
      }).then(function(result) {
        return result.json();
      }).then(function(response) {
        if (response.status == "error") {
          throw response.error;
        } else {
          sessionStorage.setItem('verification_session_id', response.session.id);
          return stripe.verifyIdentity(response.session.client_secret);
        }
      }).then(function(result) {
        if (result.error) {
          t.kycButtonDisabled = false;
          sessionStorage.setItem('verification_session_error', JSON.stringify(result.error));
        } else{
          t.kycButtonText = "Verification In Progress"
          t.kycButtonDisabled = true;
        }
      }).catch(function(error) {
        const errorMessageContainer = document.querySelector('.error-message');
        if (errorMessageContainer) {
          errorMessageContainer.classList.remove('hidden');
        }
      }).finally(function() {
        
      });
      return;
    },
    async request_project_analytics() {
      const token = await this.$auth.getTokenSilently();
      const path = `${this.$domainPath}/request-project-analytics`;
      fetch(path, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
      })
        .then((result) => result.json())
        .then((res) => {
          this.activeUsers = res['activeUsers'];
          this.totalTransactions = res['totalTransactions'];
          this.usdYTD = res['usdYTD'];
          this.cureYTD = res['cureYTD'];
          this.usdWTD = res['usdWTD'];
          this.cureWTD = res['cureWTD'];
        });
    }
  },
  created() {
    /*this.get_current_price();
    this.email = this.$router.app.$auth.user.email
    this.get_user(this.email);
    this.get_user_data(this.email);
    this.get_transfer_data(this.email);
    this.get_user_wallet(this.email);
    this.get_chart_data("day");
    this.request_project_analytics();

    if(this.$router.app.$auth.user['https://pay.curetoken.net/roles'][0] === '_pf') {
      this.proMode = true;
    }*/
    window.location.href = 'https://www.curepayapp.com/dashboard';
  },
  data() {
    return {
      apiMessage: "",
      minNum: 0,
      maxNum: 0,
      difference: '-',
      isFormValid: false,
      transferAlert: false,
      width: 2,
      radius: 10,
      padding: 8,
      lineCap: 'round',
      gradient: gradients[0],
      value: [],
      gradientDirection: 'top',
      gradients,
      fill: false,
      type: 'trend',
      autoLineWidth: false,
      show: false,
      currentBalance: 0,
      currentPrice: 0,
      walletAddress: '',
      transferToWallet: '',
      user: [],
      pkrequests: [],
      userData: [],
      transferData: [],
      initials: '',
      snackbar: false,
      requestPKSModal: false,
      showKey: true,
      requestedPro: false,
      curePriceAtTime: 0,
      transferForm: false,
      showPrivateKeys: false,
      activeUsers: '0',
      totalTransactions: '0',
      usdYTD: '0',
      cureYTD: '0',
      usdWTD: '0',
      cureWTD: '0',
      proMode: false,
      kycButtonDisabled: false,
      requestProFeatures: false,
      kycButtonText: "Request Pro Features",
      nftEmail: "",
      nftTxid: "",
      stripeId: "",
      nftNumber: "",
      pk: '',
      text: `An email has been sent containing your wallet address and your private keys. Please do not share your private keys with anyone, store these in a safe place.`,
      cards: ['Today', 'Yesterday'],
      drawer: null,
      links: [
        ['mdi-inbox-arrow-down', 'Inbox'],
        ['mdi-send', 'Send'],
        ['mdi-delete', 'Trash'],
        ['mdi-alert-octagon', 'Spam'],
      ],
      items: [
        { title: 'Home', icon: 'mdi-home-city' },
        { title: 'My Account', icon: 'mdi-account' },
        { title: 'Users', icon: 'mdi-account-group-outline' },
      ],
    };
  },
};
</script>
