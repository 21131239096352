<template>
  <v-container>
    <template>
      <v-row justify="center">
        <v-dialog
          v-model="whitelistModal"
          persistent
          max-width="500"
        >
          <v-card>
            <v-card-title class="text-h5">
              Your request has been sent.
            </v-card-title>
            <v-card-text>We will follow up with you shortly to coordinate the whitelisting.</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="white--text"
                color="orange"
                @click="whitelistModal = false"
              >
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>

    <v-row justify="center" >
      <v-col cols="6">
        <v-row justify="center">
          <h3 class="white--text">Whitelist transfer window will close in:</h3>
          <flip-countdown deadline="2022-1-15 12:00:00" @timeElapsed="timeElapsedHandler"></flip-countdown>

            <v-card class="pa-4 animated fadeIn" id="transferHolder">
          <v-card-title>
            <span class="text-h5">Request LBank Whitelist</span>
          </v-card-title>
          <v-card-text>
            <p class="">We can whitelist your LBank wallet in order to transfer CURE Tokens to the exchange. This will remove the 10% tax when transfering tokens.</p>
            <v-form v-model="isFormValid">
              <v-text-field
                label="LBank Wallet Address"
                required
                color="orange"
                class="mt-4"
                id="walletAddress"
                v-model="addressToWhitelist"
                :rules="[v => !!v || 'This field is required', v => /0x[a-fA-F0-9]{40}/g.test(v) || 'This is now a valid wallet address.']"
              ></v-text-field>
              <v-text-field 
                v-model="emailAddress"
                required
                color="orange"
                label="Your Email Address"
                :rules="[v => !!v || 'This field is required', v => /.+@.+\..+/.test(v) || 'Must be a valid email.']"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="orange"
              class="white--text px-8"
              :disabled="!isFormValid || disableButton"
              large
              @click="request_lbank_whitelist()"
            >
              Request Whitelist
            </v-btn>
          </v-card-actions>
        </v-card>
       
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<style>

  .animated {
    background-repeat: no-repeat;
    background-position: left top;
    -webkit-animation-duration: .5s;
    animation-duration: .5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
   
  @-webkit-keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: 1;}
  }
   
  @keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: 1;}
  }
   
  .fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
  }

  @-webkit-keyframes fadeOut {
    0% {opacity: 1;}
    100% {opacity: 0;}
  }
   
  @keyframes fadeOut {
    0% {opacity: 1;}
    100% {opacity: 0;}
  }
   
  .fadeOut {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
  }
  .flip-card__top {
    color:#ededed !important;
  }
  .flip-card__bottom, .flip-clock__slot {
    color:#ffffff !important;
  }


</style>

<script>

import FlipCountdown from 'vue2-flip-countdown'


export default {
  components: {
    FlipCountdown
  },
  watch:{

  },
  computed: {

  },
  methods: {
    timeElapsedHandler(){
      console.log("Complete");
      this.disableButton = true;
    },
    request_lbank_whitelist(userEmail) {
      
      console.log(this.addressToWhitelist);
      console.log(this.emailAddress);
      
      this.disableButton = true;
      this.whitelistModal = true;

      const path = `${this.$domainPath}/request-whitelist`;
      fetch(path, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ address: this.addressToWhitelist, email: this.emailAddress }),
      })
        .then((result) => result.json())
        .then((res) => {
          console.log(res);
        });
    },

  },
  created() {
    
  },
  data() {
    return {
      walletAddress: '',
      addressToWhitelist: '',
      emailAddress: '',
      isFormValid: false,
      whitelistModal: false,
      disableButton: false,
    };
  },
};
</script>
