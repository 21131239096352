var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', [_c('v-card-text', {
    staticClass: "pa-0"
  }, [_c('v-list', {
    staticStyle: {
      "min-height": "266px"
    },
    attrs: {
      "flat": "",
      "disable": ""
    }
  }, [_c('v-list-item-group', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.inProgress,
      expression: "inProgress"
    }]
  }, [_c('v-list-item', [_c('v-list-item-content', [_c('h2', [_vm._v("Your transaction is already underway")]), _c('p', {
    staticClass: "my-2"
  }, [_vm._v("Your transaction has already started. If you do not recieve a confirmation email, please contact support at support@curetoken.net.")])])], 1)], 1), _c('v-list-item-group', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.processingError,
      expression: "processingError"
    }]
  }, [_c('v-list-item', [_c('v-list-item-content', [_c('h2', [_vm._v("An error has occurred.")]), _c('p', {
    staticClass: "my-2"
  }, [_vm._v("The transaction process has failed, but don't worry, this can be resolved. Please contact support at support@curetoken.net to report and resolve the issue.")])])], 1)], 1), _c('v-list-item-group', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.loaded,
      expression: "loaded"
    }]
  }, [_c('v-list-item', [_c('v-list-item-content', [_c('h2', [_vm._v("This process may take a few minutes.")]), _c('p', {
    staticClass: "my-2"
  }, [_vm._v("You can leave this screen. An email will be sent to you upon completion of the transaction with details on how to access your tokens.")])])], 1), _c('v-divider', {
    staticClass: "my-4"
  }), _vm._l(_vm.steps, function (step, i) {
    return _c('v-list-item', {
      key: i,
      staticClass: "pa-0"
    }, [_c('v-list-item-icon', {
      staticClass: "my-2"
    }, [step.complete ? _c('v-icon', {
      attrs: {
        "color": "orange",
        "size": "40"
      }
    }, [_vm._v(" mdi-checkbox-marked-circle ")]) : _vm.currentStep == i ? _c('v-progress-circular', {
      attrs: {
        "color": "orange",
        "size": "40",
        "indeterminate": ""
      }
    }) : _c('v-icon', {
      attrs: {
        "size": "40",
        "color": "grey lighten-2"
      }
    }, [_vm._v("mdi-checkbox-marked-circle")])], 1), _c('v-list-item-content', [_c('v-list-item-title', {
      domProps: {
        "textContent": _vm._s(step.title)
      }
    })], 1)], 1);
  })], 2)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }