var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "text-center"
  }, [_c('v-card-text', [_c('h2', {
    staticClass: "my-10"
  }, [_vm._v("UNDER MAINTENANCE")])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }