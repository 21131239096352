<template>
  <v-app>
    <span class="bg" id="bgImage"></span>
    <v-app-bar
      absolute
      :color='barTransparent'
      :src='backgroundSRC'
      elevation="0"
    >
      <v-spacer></v-spacer>
      <v-app-bar-title>
        <router-link to="/">
          <img
            src="/img/curepay-logo-small-dark.png"
            height="45"
            width="auto"
            alt="CURE Token Logo"
            style="display: block; margin:auto;"
            v-if="$route.path!='/'"
          />
        </router-link>
      </v-app-bar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn
            dark
            icon
            v-on="on"
            v-if="$route.path=='/'"
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
          <v-btn
            light
            icon
            v-on="on"
            v-else
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-card>
          <v-list dense>
            <v-list-item link v-if="!$auth.isAuthenticated" @click="login">
              <v-list-item-icon>
                <v-icon>mdi-login</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Login to My Account</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item link v-if="$auth.isAuthenticated" @click="logout">
              <v-list-item-icon>
                <v-icon>mdi-logout</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Logout</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item link v-if="$route.path=='/' && $auth.isAuthenticated" @click="login">
              <v-list-item-icon>
                <v-icon>mdi-view-dashboard</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Back to My Account</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>

        </v-card>
      </v-menu>
    </v-app-bar>

    <v-card
      class="overflow-hidden"
      tile
      v-if="$route.path=='/'"
      color="black"
      elevation="0"
    >
      <transition
          name="fade"
          mode="out-in"
        >
          <router-view></router-view>
        </transition>
    </v-card>
    <v-card
      class="overflow-hidden"
      tile
      v-else
      color="transparent"
      elevation="0"
    >
      <v-sheet style="margin-top:100px; margin-bottom:100px; background:none; min-height:400px">
        <transition
          name="fade"
          mode="out-in"
        >
          <router-view></router-view>
        </transition>
      </v-sheet>
    </v-card>
    <v-footer
      dark
      padless
      class="pb-12 ma-5"
      color="transparent"
      elevation="0"
    >
      <v-col
        class="text-center"
        cols="12"
      >
        {{ new Date().getFullYear() }} — <strong>CURE Token | The Beckley Foundation</strong>
        <br>
        <a href='https://www.curetoken.net/' target='_blank' class="white--text">www.curetoken.net</a>
         | <a href="/support" class="white--text">Need Support?</a>
      </v-col>
    </v-footer>
  </v-app>
</template>
<style>
  .bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: url( '/img/bg-orange.jpg') no-repeat center center;
    background-size: cover;
    transform: scale(1);
  }
  .fade-enter-active,
  .fade-leave-active {
    transition-duration: 0.3s;
    transition-property: opacity;
    transition-timing-function: ease;
  }

  .fade-enter,
  .fade-leave-active {
    opacity: 0
  }
</style>
<script>
export default {
  name: 'App',
  data: () => ({
    drawer: false,
    group: null,
    theme: false,
    icons: [
      'mdi-facebook',
      'mdi-twitter',
      'mdi-linkedin',
      'mdi-instagram',
    ],
  }),
  watch: {
    group() {
      this.drawer = false;
    },
  },
  computed: {
    backgroundSRC() {
      let src = '';
      if (this.$route.path === '/') src = '';
      return (src);
    },
    barTransparent(){
      let bgColor = 'white';
      if (this.$route.path === '/') bgColor = 'transparent';
      return (bgColor);
    }
  },
  methods: {
    buy() {
      this.$router.push('/');
    },
    dashboard() {
      this.$router.push('/dashboard');
    },
    login() {
      this.$auth.loginWithRedirect({
        redirect_uri: 'https://pay.curetoken.net/dashboard',
      });
    },
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      });
    },
  },
};
</script>
