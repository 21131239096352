var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "sm": "3",
      "cols": "12"
    }
  }, [_c('v-sheet', {
    staticStyle: {
      "padding": "20px"
    },
    attrs: {
      "rounded": "",
      "width": "100%",
      "elevation": "5"
    }
  }, [_c('h2', [_vm._v("CURE Pay Support")]), _c('v-divider', {
    staticClass: "my-6"
  }), _c('p', [_vm._v("Our goal is to make purchasing CURE Tokens easy. We're also making it easy to get support obtain the answers to any questions that you may have.")]), _c('v-divider', {
    staticClass: "my-6"
  }), _c('h3', [_vm._v("Requesting Support")]), _c('p', [_vm._v("If at any point you have additional questions, please reach out to us by submitting a request below.")]), _c('p', [_c('a', {
    attrs: {
      "href": "mailto:support@curetoken.net"
    }
  }, [_vm._v("support@curetoken.net")])])], 1)], 1), _c('v-col', {
    attrs: {
      "sm": "9",
      "cols": "12"
    }
  }, [_c('v-sheet', {
    staticStyle: {
      "padding": "20px"
    },
    attrs: {
      "rounded": "",
      "width": "100%",
      "elevation": "5"
    }
  }, [_c('h2', {
    staticClass: "mb-6"
  }, [_vm._v("Frequently Asked Questions")]), _c('v-expansion-panels', _vm._l(_vm.faqs, function (item) {
    return _c('v-expansion-panel', {
      key: item
    }, [_c('v-expansion-panel-header', [_c('h3', [_vm._v(_vm._s(item.question))])]), _c('v-expansion-panel-content', [_vm._v(" " + _vm._s(item.answer) + " ")])], 1);
  }), 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }