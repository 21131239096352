<template>
  <v-container>
    <v-row justify="center" >
      <v-col sm="3" cols="12">
        <v-sheet
          rounded
          width="100%"
          elevation="5"
          style="padding:20px;"
        >
          <h2>CURE Pay Support</h2>
          <v-divider class="my-6"></v-divider>
          <p>Our goal is to make purchasing CURE Tokens easy. We're also making it easy to get support obtain the answers to any questions that you may have.</p>
          <v-divider class="my-6"></v-divider>
          <h3>Requesting Support</h3>
          <p>If at any point you have additional questions, please reach out to us by submitting a request below.</p>
          <p><a href="mailto:support@curetoken.net">support@curetoken.net</a></p>
        </v-sheet>
      </v-col>
      <v-col sm="9" cols="12">
        <v-sheet
          rounded
          width="100%"
          elevation="5"
          style="padding:20px;"
        >
          <h2 class="mb-6">Frequently Asked Questions</h2>
          <v-expansion-panels>
            <v-expansion-panel
              v-for="item in faqs"
              :key="item"

            >
              <v-expansion-panel-header>
                <h3>{{ item.question }}</h3>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                {{ item.answer }}
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>
<style>
</style>
<script>

export default {  
  data() {
    return {
      faqs: [
        {
          question: "How do I buy CURE Tokens?",
          answer: "It's as simple as selecting the amount you would like to buy and then submit your payment information. Once the transaction has been completed, the magic happens. You will receive an email with details of your transaction and a link to your secure dashboard. There you will be able to review the transaction, view your tokens and more.",
        },
        {
          question: "Am I really buying CURE Tokens?",
          answer: "Yes, you are purchasing the same tokens that are available on PancakeSwap. We've developed an automatic way to do that so that you don't need to know the complexities of wallets, Binance Smart Chain, exchanges, swapping, and the other complexities of buying cryptocurrencies. ",
        },
        {
          question: "Do I need a crypto wallet?",
          answer: "No, CURE Pay will automatically generate a secure and encrypted wallet for you. And this wallet is yours. If you want to import your new wallet into any wallet software, just request your private keys and you will be able to import it into the wallet of your choice.",
        },
        {
          question: "Can I move my tokens to another wallet?",
          answer: "Yes, these are your tokens. You have full control over them in the same way you do with any other wallet. Keep in mind that the CURE Token contract has a 10% tax built into it that supports the project and the charity. Moving tokens around will reduce the total amount of tokens that you own with each transaction.",
        },
        {
          question: "Can I redeem my private keys?",
          answer: "Yes, once logged into the secure dashboard, you will be able to request your private keys. CURE Pay will unlock the ability to view your private keys after the transaction has cleared. This can take anywhere from 24 hours to 5 business days. Don't worry, your tokens are safe and secure during this time period. This waiting period is mandatory in order to protect against fraud and manipulation of the product. In the future, we will build in a KYC functionality that will allow you to redeem your private keys sooner in exchange for a KYC.",
        },
        {
          question: "How do I sell my tokens?",
          answer: "Selling your tokens is done in the same way that buying and selling off of PancakeSwap is done today. You would need to import your new wallet into a wallet software of your choice. Once this is done, head over to PancakeSwap, connect your wallet, select the amount you want to sell, change the slippage to 10%, and hit trade. In the future, we will be building in a feature to sell your tokens directly from the dashboard.",
        },
      ],
    }
  },
}
</script>
