<template>
  <v-card>
    <v-card-text class="pa-0" >
      <v-list flat disable style="min-height:266px;">
        <v-list-item-group
          v-show="inProgress"
        >
          <v-list-item>
            <v-list-item-content>
              <h2>Your transaction is already underway</h2>
              <p class="my-2">Your transaction has already started. If you do not recieve a confirmation email, please contact support at support@curetoken.net.</p>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
        <v-list-item-group
          v-show="processingError"
        >
          <v-list-item>
            <v-list-item-content>
              <h2>An error has occurred.</h2>
              <p class="my-2">The transaction process has failed, but don't worry, this can be resolved. Please contact support at support@curetoken.net to report and resolve the issue.</p>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
        <v-list-item-group
          v-show="loaded"
        >
          <v-list-item>
            <v-list-item-content>
              <h2>This process may take a few minutes.</h2>
              <p class="my-2">You can leave this screen. An email will be sent to you upon completion of the transaction with details on how to access your tokens.</p>
          </v-list-item-content>
          </v-list-item>
          <v-divider class="my-4"></v-divider>
          <v-list-item
            v-for="(step, i) in steps"
            :key="i"
            class="pa-0"
          >
            <v-list-item-icon class="my-2">
              <v-icon v-if="step.complete" color="orange" size="40">
                mdi-checkbox-marked-circle
              </v-icon>
              <v-progress-circular
                v-else-if="currentStep == i"
                color="orange"
                size="40"
                indeterminate
              ></v-progress-circular>
              <v-icon v-else size="40" color="grey lighten-2">mdi-checkbox-marked-circle</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="step.title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';
import axios from 'axios';

export default {
  data() {
    return {
      loaded: false,
      processingError: false,
      inProgress: false,
      previousBalance: "",
      BNBtokenHash: "",
      CUREtokenHash: "",
      myCURETimeout: "",
      myBNBTimeout: "",
      steps: [
        {
          title: 'Processing Payment',
          complete: true,
        },
        {
          title: 'Creating Account',
          complete: false,
        },
        {
          title: 'Acquiring Your Tokens',
          complete: false,
        },
        {
          title: 'Transaction Confirmed',
          complete: false,
        },
      ],
      currentStep: 1,
    };
  },
  watch: {
    processingError() {
      this.loaded = false;
      this.processingError = true;
      this.inProgress = false;
    },
    inProgress() {
      this.loaded = false;
      this.processingError = false;
      this.inProgress = true;
    }
  },
  computed: {
    purchased: {
      get: function(){
        return this.$store.state.purchased
      },
      set: function(val){
        this.$store.dispatch('SET_PURCHASED', val);
      },
    },
  },
  methods: {
    getCheckoutStatus(){
      console.log("getCheckoutStatus");
      return Promise.resolve({ options: [] });
    },
    checkForBNBTransactionSuccess(string) {
      console.log("checkForBNBTransactionSuccess");

      fetch(`${this.$domainPath}/get-checkout-session`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ session_id: this.$route.query.session_id }),
          })
            .then((result) => result.json())
            .then((data) => {
              setTimeout(() => { this.steps[3].complete = true; this.currentStep = 4; }, 2000);
              setTimeout(() => { this.steps[4].complete = true; this.currentStep = 5; }, 2000);
              setTimeout(() => { this.$emit('complete'); }, 5000);
            });

      return Promise.resolve({ options: [] });
    },
    checkForCURETransactionSuccess(string) {
      console.log("checkForCURETransactionSuccess");
    }
  },
  mounted() {
    
    if (this.$route.query.id) {
      console.log("Begin purchase process");
      this.$emit('complete')

      fetch(`${this.$domainPath}/purchase-cure-paypal`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ session_id: this.$route.query.id }),
        })
          .then((result) => result.json())
          .then((data) => {
            console.log(data);

            if(data.status === 'purchased') {
              this.$emit('complete');
              console.log("Purchased Already");
              return;
            } else {
              console.log("Begin purchase process");
              this.loaded = true;
              this.steps[0].complete = true;
              this.currentStep = 1;

              setTimeout(() => { this.steps[1].complete = true; this.currentStep = 2; }, 1000);
              setTimeout(() => { this.steps[2].complete = true; this.currentStep = 3; }, 3000);
              setTimeout(() => { this.steps[3].complete = true; this.currentStep = 4; }, 4000);
              setTimeout(() => { this.$emit('complete'); }, 5000);
            }
          });

      return;
    }
    if (this.$route.query.session_id) {
      window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);

      fetch(`${this.$domainPath}/purchase-cure`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ session_id: this.$route.query.session_id }),
        })
          .then((result) => result.json())
          .then((data) => {
            console.log(data);

            if(data.status === 'purchased') {
              this.$emit('complete');
              console.log("Purchased Already");
              return;
            } else {
              console.log("Begin purchase process");
              this.loaded = true;
              this.steps[0].complete = true;
              this.currentStep = 1;

              setTimeout(() => { this.steps[1].complete = true; this.currentStep = 2; }, 1000);
              setTimeout(() => { this.steps[2].complete = true; this.currentStep = 3; }, 3000);
              setTimeout(() => { this.steps[3].complete = true; this.currentStep = 4; }, 4000);
              setTimeout(() => { this.$emit('complete'); }, 5000);
            }
          });

      return;
    }
    return;
    }
  };
 
</script>

<style>
  .v-list-item__icon{
    margin-right:10px !important;
    padding-right:10px;
  }
</style>
