var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "text-center"
  }, [_c('v-card-text', [_c('h1', [_vm._v("Thank you for your purchase.")]), _c('p', {
    staticClass: "py-5"
  }, [_vm._v(" Your tokens have been secured and can be accessed at any time. Shortly, you'll receive an email with your confirmation receipt. ")])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }