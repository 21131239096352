import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    createPersistedState({ paths: ['purchased'] }),
  ],
  state: {
    purchased: '',
  },
  actions: {
    SET_PURCHASED: ({ commit }, purchased) => {
      commit('setpurchased', purchased);
    },
  },
  mutations: {
    setpurchased(state, purchased) {
      state.purchased = purchased;
    },
  },
  getters: {
  },
});
