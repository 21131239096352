<template>
  <v-card class="text-center">
    <v-card-text >
      <h1>Thank you for your purchase.</h1>
      <p class="py-5">
        Your tokens have been secured and can be accessed at any time.
        Shortly, you'll receive an email with your confirmation receipt.
      </p>
    </v-card-text>

  </v-card>
</template>

<script>
export default {
};
</script>
