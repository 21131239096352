var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [[_c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-dialog', {
    attrs: {
      "persistent": "",
      "max-width": "500"
    },
    model: {
      value: _vm.transferAlert,
      callback: function callback($$v) {
        _vm.transferAlert = $$v;
      },
      expression: "transferAlert"
    }
  }, [_c('v-card', {
    staticClass: "pa-4"
  }, [_c('v-card-title', {
    staticClass: "text-h5"
  }, [_vm._v(" Sure, we can help you with that. ")]), _c('v-card-text', [_vm._v("Please reach out to "), _c('a', {
    attrs: {
      "href": "mailto:support@curetoken.net"
    }
  }, [_vm._v("support@curetoken.net")]), _vm._v(" to request support on how to sell your tokens. Alternatively, you can request your private keys from the Pro Features Mode and import them into a software wallet of your choice.")]), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    staticClass: "white--text",
    attrs: {
      "color": "orange",
      "large": ""
    },
    on: {
      "click": function click($event) {
        _vm.transferAlert = false;
      }
    }
  }, [_vm._v(" Close ")])], 1)], 1)], 1)], 1)], _c('div', {
    staticClass: "text-center"
  }, [_c('v-dialog', {
    staticClass: "pa-8",
    attrs: {
      "max-width": "500px"
    },
    model: {
      value: _vm.requestProFeatures,
      callback: function callback($$v) {
        _vm.requestProFeatures = $$v;
      },
      expression: "requestProFeatures"
    }
  }, [_c('v-card', {
    staticClass: "pa-8"
  }, [_c('v-card-text', {
    staticClass: "pa-0"
  }, [_c('v-row', {}, [_c('v-col', {
    staticClass: "pa-8",
    attrs: {
      "sm": "12",
      "cols": "12"
    }
  }, [_c('h2', [_vm._v(" Request With KYC ")]), _c('h4', {
    staticClass: "mb-4"
  }, [_vm._v(" Approximately 1-5 Minutes ")]), _c('p', [_vm._v("This feature will allow you to sell, transfer and obtain your private keys. You can enable this feature by completing a KYC that requires you to provide your ID for validation.")]), _c('v-btn', {
    staticClass: "white--text",
    attrs: {
      "color": "orange",
      "elevation": "0",
      "block": "",
      "large": ""
    },
    on: {
      "click": function click($event) {
        return _vm.request_kyc();
      }
    }
  }, [_vm._v(" Perform KYC ")])], 1)], 1)], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "max-width": "500px"
    },
    model: {
      value: _vm.transferForm,
      callback: function callback($$v) {
        _vm.transferForm = $$v;
      },
      expression: "transferForm"
    }
  }, [_c('v-card', {
    staticClass: "pa-4",
    attrs: {
      "id": "transferHolder"
    }
  }, [_c('v-card-title', [_c('span', {
    staticClass: "text-h5"
  }, [_vm._v("Transfer Your Tokens")])]), _c('v-card-text', [_c('p', {}, [_vm._v("You can now transfer your tokens to a wallet of your choice without a 10% tax, just cover the transaction fee.")]), _c('p', {
    staticClass: "red--text"
  }, [_vm._v("WARNING: This will tranfer all of your CURE Tokens to this wallet. Be certain that this is accurate as there is no way to recover your tokens.")]), _c('v-form', {
    model: {
      value: _vm.isFormValid,
      callback: function callback($$v) {
        _vm.isFormValid = $$v;
      },
      expression: "isFormValid"
    }
  }, [_c('v-text-field', {
    staticClass: "mt-4",
    attrs: {
      "label": "BEP-20 Wallet Address",
      "required": "",
      "color": "orange",
      "id": "walletAddress",
      "rules": [function (v) {
        return !!v || 'This field is required';
      }, function (v) {
        return /0x[a-fA-F0-9]{40}/g.test(v) || 'This is now a valid wallet address.';
      }]
    },
    model: {
      value: _vm.transferToWallet,
      callback: function callback($$v) {
        _vm.transferToWallet = $$v;
      },
      expression: "transferToWallet"
    }
  })], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "grey",
      "text": "",
      "large": ""
    },
    on: {
      "click": function click($event) {
        _vm.transferForm = false;
      }
    }
  }, [_vm._v(" Cancel ")]), _c('v-btn', {
    staticClass: "white--text px-4",
    attrs: {
      "color": "orange",
      "disabled": !_vm.isFormValid,
      "large": ""
    },
    on: {
      "click": function click($event) {
        return _vm.transferTokens();
      }
    }
  }, [_vm._v(" Transfer Tokens ")])], 1)], 1)], 1)], 1), _c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-alert', {
    attrs: {
      "border": "bottom",
      "colored-border": "",
      "type": "info",
      "elevation": "2"
    }
  }, [_vm._v(" The 0% transfer feature is now active. You can transfer all of your tokens to a wallet of your choice. In order to use this feature, you must complete a KYC by clicking on Request Pro Features. ")]), _c('v-alert', {
    attrs: {
      "border": "bottom",
      "colored-border": "",
      "type": "error",
      "elevation": "2"
    }
  }, [_vm._v(" The crypto space is filled with frauds, scams, and people that wants to take your tokens. Be sure to only interact with "), _c('a', {
    attrs: {
      "href": "mailto:support@curetoken.net"
    }
  }, [_vm._v("support@curetoken.net")]), _vm._v(" and "), _c('a', {
    attrs: {
      "href": "https://t.me/jacobbeckley",
      "target": "_blank"
    }
  }, [_vm._v("@jacobbeckley")]), _vm._v(" on Telegram. ")])], 1)], 1), _c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "sm": "4",
      "cols": "12"
    }
  }, [_c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-fade-transition', [_c('v-sheet', {
    staticClass: "mb-4 mx-4 animated fadeIn",
    staticStyle: {
      "padding": "20px",
      "opacity": "0",
      "position": "relative"
    },
    attrs: {
      "rounded": "",
      "width": "100%",
      "elevation": "5"
    }
  }, [_c('v-progress-circular', {
    staticClass: "mb-4 mx-4 animated loader",
    class: {
      fadeOut: _vm.show
    },
    attrs: {
      "size": 40,
      "width": 5,
      "color": "grey lighten-3",
      "indeterminate": ""
    }
  }), _c('div', {
    staticClass: "animated",
    class: {
      fadeIn: _vm.show
    },
    staticStyle: {
      "opacity": "0"
    }
  }, [_c('v-list-item', {
    staticClass: "mb-6 ma-0 pa-0"
  }, [_c('v-avatar', {
    staticClass: "profile-picture white--text",
    attrs: {
      "color": "orange",
      "size": "65"
    }
  }, [_c('h1', [_vm._v(_vm._s(_vm.user.initials))])]), _c('v-list-item-content', [_c('v-list-item-subtitle', [_c('div', {
    staticClass: "ma-4"
  }, [_c('h2', {
    staticClass: "grey--text text--darken-1"
  }, [_vm._v(_vm._s(_vm.user.fullname))]), _c('p', {
    staticClass: "grey--text text--darken-1 text-wrap caption"
  }, [_vm._v(_vm._s(_vm.shortenLength(_vm.email)))])])])], 1)], 1), _c('v-divider'), _c('v-list-item', {
    staticClass: "mt-8"
  }, [_c('v-list-item-content', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_c('v-list-item-title', {
    staticClass: "grey--text text--darken-1"
  }, [_vm._v(" TOTAL CURE OWNED ")]), _c('v-list-item-subtitle', [_c('h1', {
    staticClass: "orange--text font-weight-light"
  }, [_vm._v(_vm._s(_vm.numberWithCommas(_vm.currentBalance.toFixed(3))))])])], 1)], 1), _c('v-list-item', {
    staticClass: "mb-8"
  }, [_c('v-list-item-content', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_c('v-list-item-title', {
    staticClass: "grey--text text--darken-1"
  }, [_vm._v(" CURRENT CURE PRICE ")]), _c('v-list-item-subtitle', [_c('h1', {
    staticClass: "orange--text font-weight-light"
  }, [_vm._v("$" + _vm._s(_vm.currentPrice))])])], 1)], 1), _c('v-divider'), _c('v-list-item', {
    staticClass: "mt-8"
  }, [_c('v-list-item-content', {
    staticClass: "py-2",
    staticStyle: {
      "text-align": "center"
    }
  }, [_c('v-list-item-subtitle', [_c('v-btn', {
    staticClass: "white--text py-0",
    attrs: {
      "color": "orange",
      "block": "",
      "large": ""
    },
    on: {
      "click": function click($event) {
        return _vm.buy_tokens();
      }
    }
  }, [_vm._v("BUY Tokens")])], 1)], 1)], 1), _c('v-list-item', {
    staticClass: "py-0"
  }, [_c('v-list-item-content', {
    staticClass: "py-2",
    staticStyle: {
      "text-align": "center"
    }
  }, [_c('v-list-item-subtitle', [_c('v-btn', {
    staticClass: "white--text",
    attrs: {
      "color": "grey",
      "block": "",
      "large": "",
      "disabled": _vm.currentBalance <= 0
    },
    on: {
      "click": function click($event) {
        _vm.transferAlert = true;
      }
    }
  }, [_vm._v("Sell Tokens")])], 1)], 1)], 1), _vm.proMode ? _c('div', [_c('v-list-item', {}, [_c('v-list-item-content', {
    staticClass: "py-2 mb-8",
    staticStyle: {
      "text-align": "center"
    }
  }, [_c('v-list-item-subtitle', [_c('v-btn', {
    staticClass: "white--text",
    attrs: {
      "color": "grey",
      "block": "",
      "large": "",
      "disabled": _vm.currentBalance <= 0
    },
    on: {
      "click": function click($event) {
        return _vm.transfer_modal();
      }
    }
  }, [_vm._v("Transfer Tokens")])], 1)], 1)], 1)], 1) : _vm._e()], 1)], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "sm": "8",
      "cols": "12"
    }
  }, [_c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-sheet', {
    staticClass: "mx-4 animated fadeIn",
    staticStyle: {
      "padding": "20px",
      "opacity": "0",
      "position": "relative"
    },
    attrs: {
      "rounded": "",
      "width": "100%",
      "elevation": "5"
    }
  }, [_c('v-progress-circular', {
    staticClass: "mb-4 mx-4 animated loader",
    class: {
      fadeOut: _vm.show
    },
    attrs: {
      "size": 40,
      "width": 5,
      "color": "grey lighten-3",
      "indeterminate": ""
    }
  }), _c('div', {
    staticClass: "animated",
    class: {
      fadeIn: _vm.show
    },
    staticStyle: {
      "opacity": "0"
    }
  }, [_c('v-card', {
    staticClass: "mx-auto",
    attrs: {
      "transparent": "",
      "elevation": "0"
    }
  }, [!_vm.proMode ? _c('v-container', [_c('v-row', {
    staticClass: "mb-2",
    attrs: {
      "justify": "end"
    }
  }, [_c('v-btn', {
    staticClass: "white--text",
    attrs: {
      "color": "grey",
      "small": "",
      "elevation": "0",
      "disabled": _vm.kycButtonDisabled
    },
    on: {
      "click": function click($event) {
        _vm.requestProFeatures = true;
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v(" mdi-check-decagram ")]), _vm._v(" " + _vm._s(_vm.kycButtonText) + " ")], 1)], 1)], 1) : _vm._e(), !_vm.proMode ? _c('v-divider', {
    staticClass: "mb-4"
  }) : _vm._e(), _c('v-card-title', [_c('v-row', {
    attrs: {
      "align": "start"
    }
  }, [_c('div', {
    staticClass: "text-caption grey--text text-uppercase"
  }, [_c('p', {
    staticClass: "grey--text text--darken-1 ml-1 mb-0"
  }, [_vm._v("Current Price "), _vm.difference.includes('-') ? _c('span', {}, [_c('span', {
    staticClass: "red--text",
    domProps: {
      "textContent": _vm._s(_vm.difference || '-')
    }
  }), _c('v-icon', {
    staticClass: "red--text",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-menu-down")])], 1) : _c('span', {}, [_c('span', {
    staticClass: "green--text",
    domProps: {
      "textContent": _vm._s(_vm.difference || '-')
    }
  }), _c('v-icon', {
    staticClass: "green--text",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-menu-up")])], 1)]), _c('span', {
    staticClass: "text-h3 orange--text font-weight-light mt-0",
    staticStyle: {
      "line-height": ".8em"
    },
    domProps: {
      "textContent": _vm._s(_vm.currentPrice || '—')
    }
  })]), _c('v-spacer'), _c('span', {
    staticClass: "text-caption grey--text text-uppercase text-right"
  }, [_c('span', {
    staticClass: "d-block red--text"
  }, [_vm._v("LOW: " + _vm._s(_vm.minNum.toFixed(6)) + " ")]), _c('span', {
    staticClass: "d-block green--text",
    staticStyle: {
      "line-height": ".9em"
    }
  }, [_vm._v("HIGH: " + _vm._s(_vm.maxNum.toFixed(6)))])])], 1)], 1), _c('v-sheet', {
    attrs: {
      "color": "transparent"
    }
  }, [_c('div', [_c('v-sparkline', {
    ref: "sparkline",
    attrs: {
      "value": _vm.value,
      "gradient": _vm.gradient,
      "smooth": _vm.radius || false,
      "padding": _vm.padding,
      "line-width": _vm.width,
      "stroke-linecap": _vm.lineCap,
      "gradient-direction": _vm.gradientDirection,
      "fill": _vm.fill,
      "type": _vm.type,
      "auto-line-width": _vm.autoLineWidth,
      "auto-draw": "",
      "height": "70px",
      "color": "grey"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn(item) {
        return [_vm._v(" " + _vm._s(Number(item.value).toFixed(4)) + " ")];
      }
    }])
  })], 1)])], 1), _c('v-divider'), this.$router.app.$auth.user['https://pay.curetoken.net/roles'][0] === 'admin' ? _c('div', [_c('v-form', [_c('v-container', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "E-mail",
      "required": ""
    },
    model: {
      value: _vm.nftEmail,
      callback: function callback($$v) {
        _vm.nftEmail = $$v;
      },
      expression: "nftEmail"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "number",
      "required": ""
    },
    model: {
      value: _vm.nftNumber,
      callback: function callback($$v) {
        _vm.nftNumber = $$v;
      },
      expression: "nftNumber"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "txid",
      "required": ""
    },
    model: {
      value: _vm.nftTxid,
      callback: function callback($$v) {
        _vm.nftTxid = $$v;
      },
      expression: "nftTxid"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "stripeId",
      "required": ""
    },
    model: {
      value: _vm.stripeId,
      callback: function callback($$v) {
        _vm.stripeId = $$v;
      },
      expression: "stripeId"
    }
  })], 1), _c('v-btn', {
    staticClass: "white--text px-4",
    attrs: {
      "color": "orange",
      "large": ""
    },
    on: {
      "click": function click($event) {
        return _vm.sendEmail();
      }
    }
  }, [_vm._v(" Send Email ")])], 1)], 1)], 1)], 1) : _vm._e(), this.$router.app.$auth.user['https://pay.curetoken.net/roles'][0] === 'admin' ? _c('div', [_c('h2', {
    staticClass: "mt-6 grey--text text--darken-1 font-weight-light"
  }, [_vm._v("Admin Tools")]), _c('v-divider'), _c('v-sheet', {
    staticClass: "text-center my-8 grey lighten-4"
  }, [_c('v-row', {
    staticClass: "text-center pa-4"
  }, [_c('v-col', {
    attrs: {
      "sm": "3",
      "md": "3",
      "lg": "3",
      "cols": "12"
    }
  }, [_c('h5', {
    staticClass: "grey--text text--darken-1 font-weight-light"
  }, [_vm._v("TOTAL TRANSACTIONS")]), _c('h2', {
    staticClass: "orange--text font-weight-light",
    staticStyle: {
      "line-height": "0.9em"
    }
  }, [_vm._v(_vm._s(this.totalTransactions))])]), _c('v-col', {
    attrs: {
      "sm": "3",
      "md": "3",
      "lg": "3",
      "cols": "12"
    }
  }, [_c('h5', {
    staticClass: "grey--text text--darken-1 font-weight-light"
  }, [_vm._v("TOTAL USERS")]), _c('h2', {
    staticClass: "orange--text font-weight-light",
    staticStyle: {
      "line-height": "0.9em"
    }
  }, [_vm._v(_vm._s(this.activeUsers))])]), _c('v-col', {
    attrs: {
      "sm": "3",
      "md": "3",
      "lg": "3",
      "cols": "12"
    }
  }, [_c('h5', {
    staticClass: "grey--text text--darken-1 font-weight-light"
  }, [_vm._v("TOTAL USD")]), _c('h2', {
    staticClass: "orange--text font-weight-light",
    staticStyle: {
      "line-height": "0.9em"
    }
  }, [_vm._v("$" + _vm._s(_vm.numberWithCommas(this.usdYTD)))]), _c('h5', {
    staticClass: "grey--text text--darken-1 font-weight-light"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("$" + _vm._s(_vm.numberWithCommas(this.usdWTD)))]), _vm._v(" TODAY")])]), _c('v-col', {
    attrs: {
      "sm": "3",
      "md": "3",
      "lg": "3",
      "cols": "12"
    }
  }, [_c('h5', {
    staticClass: "grey--text text--darken-1 font-weight-light"
  }, [_vm._v("TOTAL CURE")]), _c('h2', {
    staticClass: "orange--text font-weight-light",
    staticStyle: {
      "line-height": "0.9em"
    }
  }, [_vm._v(_vm._s(_vm.numberWithCommas(this.cureYTD)))]), _c('h5', {
    staticClass: "grey--text text--darken-1 font-weight-light"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(_vm._s(_vm.numberWithCommas(this.cureWTD)))]), _vm._v(" TODAY")])])], 1)], 1), _c('v-divider')], 1) : _vm._e(), this.transferData != 0 ? _c('div', [_c('h2', {
    staticClass: "mt-6 grey--text text--darken-1 font-weight-light"
  }, [_vm._v("Transfer Summary")]), _c('v-simple-table', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn() {
        return [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-left orange--text text--lighten-1",
          staticStyle: {
            "max-width": "50px"
          }
        }, [_vm._v(" DATE ")]), _c('th', {
          staticClass: "text-left orange--text text--lighten-1",
          staticStyle: {
            "max-width": "50px"
          }
        }, [_vm._v(" TOTAL CURE ")]), _c('th', {
          staticClass: "text-left orange--text text--lighten-1"
        }, [_vm._v(" ADDRESS ")])])]), _c('tbody', _vm._l(_vm.transferData, function (item) {
          return _c('tr', {
            key: item[0]
          }, [_c('td', {
            staticClass: "text-left grey--text text--darken-1",
            staticStyle: {
              "max-width": "50px"
            }
          }, [_vm._v(_vm._s(_vm.formatDate(item[0])))]), _c('td', {
            staticClass: "text-left grey--text text--darken-1",
            staticStyle: {
              "max-width": "50px"
            }
          }, [_vm._v(_vm._s(_vm.numberWithCommas(item[3])))]), _c('td', {
            staticClass: "text-left grey--text text--darken-1"
          }, [_vm._v(_vm._s(item[2]))])]);
        }), 0)];
      },
      proxy: true
    }], null, false, 942757979)
  })], 1) : _vm._e(), _c('h2', {
    staticClass: "mt-6 grey--text text--darken-1 font-weight-light"
  }, [_vm._v("Purchase Summary")]), _c('v-simple-table', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn() {
        return [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-left orange--text text--lighten-1"
        }, [_vm._v(" DATE ")]), _c('th', {
          staticClass: "text-left orange--text text--lighten-1"
        }, [_vm._v(" TOTAL CURE ")]), _c('th', {
          staticClass: "text-left orange--text text--lighten-1"
        }, [_vm._v(" TOTAL USD ")]), _c('th', {
          staticClass: "text-left orange--text text--lighten-1"
        }, [_vm._v(" CURE PRICE ")])])]), _c('tbody', _vm._l(_vm.userData, function (item) {
          return _c('tr', {
            key: item[0]
          }, [_c('td', {
            staticClass: "text-left grey--text text--darken-1"
          }, [_vm._v(_vm._s(_vm.formatDate(item[0])))]), _c('td', {
            staticClass: "text-left grey--text text--darken-1"
          }, [_vm._v(_vm._s(_vm.numberWithCommas(item[4])))]), _c('td', {
            staticClass: "text-left grey--text text--darken-1"
          }, [_vm._v("$" + _vm._s(_vm.addDecimal(item[3])))]), _c('td', {
            staticClass: "text-left grey--text text--darken-1"
          }, [_c('span', {
            domProps: {
              "textContent": _vm._s(item[9] || '—')
            }
          })])]);
        }), 0)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1)], 1)], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }